@charset "utf-8";

// Our variables
$base-font-family: "Roboto", Helvetica, Arial, sans-serif;


$mdc-theme-primary: #1c5e1b;
$mdc-theme-secondary: #feeae6;
$mdc-theme-on-primary: #442b2d;
$mdc-theme-on-secondary: #442b2d;

.ge-green-bg{
    min-height: 100%;
}
.mdc-top-app-bar{
    background-color: $mdc-theme-primary;
}
.mdc-top-app-bar a{
    color: inherit;
    text-decoration: none;
}

h1, h2, h3, h4{
    margin-top: 1.2em;
    margin-bottom: 0.2em;
}
html, body{
    height: 100%;
    color: #303030;
}
body{
    margin: 0;
    background-color: #303030;
}
.ge-center{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.placeholder{
    text-align: center;
    min-height: 100%;
    background-color: $mdc-theme-primary;
    color: #FFFFFF;
}
.placeholder.dark{
    background-color: #333;
    color: #FFFFFF;
}
footer{
    color: #CCCCCC;
    padding: 20px 0 20px;
}
footer a{
    text-decoration: none;
    color: inherit;
}
.center{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.content-wrapper{
    background-color: #FFFFFF;
    overflow: hidden;
}
.footer-content{
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
}
.ge-content{
    padding: 100px 20px;
    max-width: 500px;
    margin: 0 auto;
}
.ge-bar-navi{
    margin-left: 30px;
}
.ge-bar-navi * {
    padding-left: 10px;
}
.mdc-top-app-bar__title{
    padding-left: 0px;
}

.ge-start{
    padding: 50px 0;
}
.ge-full-img{
    position: relative;
    padding-top: 56.25%;
}
.ge-full-img > .ge-jumbo-center{
    position: absolute;
    color: #fff;
    margin: 0 auto;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    text-align: center;
    text-shadow: 0 0 2px #000;
}

.ge-split{
    display: flex;
    align-items: stretch;
    flex-direction: row;
    text-align: center;
    border-bottom: 1px solid #ddd;
  }
  .ge-polygon{
      background-image: url('../asset/img/polygons.svg');
      background-position-x: center;
      background-repeat: repeat;
  }
  .ge-text-right > .ge-image{
    order: -1;
  }
  .ge-text-right > .ge-split-content{
    order: 1;
  }
  .ge-split > *{
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
    align-self: stretch;
  }
  .ge-split > .ge-split-content{
      max-width: 600px;
      margin: 0 auto;
    padding: 2.5rem 2rem 3rem;
    flex-grow: 2;
  }